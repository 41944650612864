import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useField } from 'react-final-form';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import css from './MapFormField.module.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

const MapField = ({ name }) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const {
    input: { value, onChange },
  } = useField(name);

  const [map, setMap] = useState(null);
  const [error, setError] = useState(null);
  const mapContainer = useRef(null);
  const marker = useRef(null);
  const geocoder = useRef(null);

  const reverseGeocode = async (lng, lat) => {
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`
    );
    return response.data.features[0]?.place_name;
  };

  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      const longitude = isNaN(value.longitude) ? 0 : value.longitude;
      const latitude = isNaN(value.latitude) ? 0 : value.latitude;

      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [longitude, latitude],
        zoom: 5,
      });

      const newMarker = new mapboxgl.Marker({ draggable: true })
        .setLngLat([longitude, latitude])
        .addTo(newMap);

      newMarker.on('dragend', async () => {
        const lngLat = newMarker.getLngLat();
        const address = await reverseGeocode(lngLat.lng, lngLat.lat);
        onChange({
          longitude: lngLat.lng,
          latitude: lngLat.lat,
          address,
        });
      });

      newMap.on('load', () => {
        setMap(newMap);
        marker.current = newMarker;
      });

      geocoder.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      });

      geocoder.current.on('result', function(e) {
        marker.current.setLngLat(e.result.geometry.coordinates);
        marker.current.setDraggable(true); // Re-enable dragging
        onChange({
          longitude: e.result.geometry.coordinates[0],
          latitude: e.result.geometry.coordinates[1],
          address: e.result?.place_name,
        });
      });

      document.getElementById('geocoder').appendChild(geocoder.current.onAdd(newMap));
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [map]);

  const handleChangeLatitude = e => {
    //Handle the case when the user types "-"
    if (e.target.value === '-') {
      onChange({
        longitude: value.longitude || 0,
        latitude: e.target.value,
        address: value.address,
      });
      marker.current.setLngLat([value.longitude || 0, 0]);
      map.flyTo({ center: [value.longitude || 0, 0] });
      marker.current.setDraggable(true);
      return;
    }

    //Handle the case when the user types "{number}."
    const chars = e.target.value.split('');
    if (chars[chars.length - 1] === '.') {
      const numberValue = e.target.value && Number(e.target.value);
      onChange({
        longitude: value.longitude || 0,
        latitude: e.target.value,
        address: value.address,
      });
      marker.current.setLngLat([value.longitude || 0, numberValue]);
      map.flyTo({ center: [value.longitude || 0, numberValue] });
      marker.current.setDraggable(true);
      return;
    }

    //Basic usage
    const receivedValue = e.target.value && Number(e.target.value);
    const isValid = receivedValue <= 180 && receivedValue >= -180;
    const validatedReceivedValue = isValid ? receivedValue : 0;

    if (!isValid) {
      setError(true);
    } else {
      setError(false);
    }
    onChange({
      longitude: value.longitude || 0,
      latitude: validatedReceivedValue,
      address: value.address,
    });

    marker.current.setLngLat([value.longitude || 0, validatedReceivedValue]);
    map.flyTo({ center: [value.longitude || 0, validatedReceivedValue] });
    marker.current.setDraggable(true);
  };

  const handleChangeLongitude = e => {
    //Handle the case when the user types "-"
    if (e.target.value === '-') {
      onChange({
        longitude: e.target.value,
        latitude: value.latitude || 0,
        address: value.address,
      });
      marker.current.setLngLat([0, value.latitude || 0]);
      map.flyTo({ center: [0, value.latitude || 0] });
      marker.current.setDraggable(true);
      return;
    }
    //Handle the case when the user types "{number}."
    const chars = e.target.value.split('');
    if (chars[chars.length - 1] === '.') {
      const numberValue = e.target.value && Number(e.target.value);
      onChange({
        longitude: e.target.value,
        latitude: value.latitude || 0,
        address: value.address,
      });
      marker.current.setLngLat([numberValue, value.latitude || 0]);
      map.flyTo({ center: [numberValue, value.latitude || 0] });
      marker.current.setDraggable(true);
      return;
    }
    //Basic usage
    const receivedValue = e.target.value && Number(e.target.value);
    const isValid = receivedValue <= 180 && receivedValue >= -180;
    const validatedReceivedValue = isValid ? receivedValue : 0;
    if (!isValid) {
      setError(true);
    } else {
      setError(false);
    }
    onChange({
      longitude: validatedReceivedValue,
      latitude: value.latitude || 0,
      address: value.address,
    });

    marker.current.setLngLat([validatedReceivedValue, value.latitude || 0]);
    map.flyTo({ center: [validatedReceivedValue, value.latitude || 0] });
    marker.current.setDraggable(true);
  };

  useEffect(() => {
    if (map) {
      map._markers.forEach(existingMarker => {
        if (!existingMarker._draggable) {
          existingMarker.remove();
        }
      });
    }
  });

  return (
    <div className={css.mapSection}>
      <div id="geocoder"></div>
      <div ref={el => (mapContainer.current = el)} style={{ width: '100%', height: '400px' }} />
      <div className={css.formRow}>
        <div className={css.formFld}>
          <label className={css.inputLabel}>Latitude</label>
          <input
            type="text"
            value={value?.latitude}
            onChange={handleChangeLatitude}
            className={css.latLngInput}
          />
        </div>
        <div className={css.formFld}>
          <label className={css.inputLabel}>Longitude</label>
          <input
            type="text"
            value={value?.longitude}
            onChange={handleChangeLongitude}
            className={css.latLngInput}
          />
        </div>
      </div>
      {error && <p className={css.error}>Latitude and longitude need to be between -180 and 180</p>}
    </div>
  );
};

export default MapField;
