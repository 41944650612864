import { ensurePaymentMethodCard, ensureStripeCustomer } from './data';
import { EXTENDED_PRICING_DATA, MOORING_BUOY } from './enums';
import { isArrayLength } from './genericHelpers';

const strapiBaseURL = process.env.REACT_APP_STRAPI_BASE_URL;

export const getUserLastSeen = user => {
  return !!user?.attributes?.profile?.protectedData?.lastSeenPending;
};

export const getUserBoatDetails = user => {
  const boatDetails = user?.attributes?.profile?.publicData?.boatDetails;
  return boatDetails;
};

export const getUserMembershipID = user => {
  const membershipID = user?.attributes?.profile?.protectedData?.membershipID;
  return membershipID;
};

export const isPayoutCharity = user => {
  return user?.attributes?.profile?.publicData?.selectedPayout === 'charity';
};

export const getCharityDetails = user => {
  return user?.attributes?.profile?.publicData?.charityOptions?.selectedOrganization;
};

export const getBoatPrice = (listing, currentUser) => {
  // Retrieve the maximum boat size for the current user
  const maxBoatSize = getUserBoatDetails(currentUser)?.length;

  // Check if the listing is for a mooring buoy
  const isBuoy = listing?.attributes?.publicData?.category === MOORING_BUOY;

  // Determine the pricing key based on boat size and listing type
  let pricingKey;
  if (!maxBoatSize) {
    pricingKey = isBuoy ? 'FOR_BUOY' : 'FOR_DOCK';
  } else {
    const isLargeBoat = Number(maxBoatSize) > 45;
    pricingKey = isLargeBoat
      ? isBuoy
        ? 'FOR_LARGE_BUOY'
        : 'FOR_LARGE_DOCK'
      : isBuoy
      ? 'FOR_BUOY'
      : 'FOR_DOCK';
  }

  // Return the corresponding pricing from the extended pricing data
  return EXTENDED_PRICING_DATA[pricingKey] / 100;
};

export const getUserDetails = user => {
  // Combine first and last names using optional chaining and nullish coalescing
  const fullName = user?.attributes?.profile?.firstName
    ? `${user?.attributes?.profile?.firstName} ${user?.attributes?.profile?.lastName}`
    : user?.attributes?.profile?.publicData?.fullName;

  // Use optional chaining and nullish coalescing for profile image
  const profileImage =
    user?.profileImage?.attributes?.variants?.default?.url ||
    user?.profileImage?.attributes?.variants?.['square-small2x']?.url ||
    null; // Set to null if no image variants found

  // Use optional chaining and nullish coalescing for email
  const email = user?.attributes?.email || user?.attributes?.profile?.publicData?.email;

  // Extract ID using optional chaining and nullish coalescing
  const id = user?.id?.uuid;
  const firstName = user?.attributes?.profile?.firstName;
  const hasDefaultPaymentMethod =
    user?.id &&
    ensureStripeCustomer(user.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(user.stripeCustomer.defaultPaymentMethod).id;
  const phoneNumber = user?.attributes?.profile?.protectedData?.phoneNumber;

  // Return object using destructuring assignment and computed property for clarity
  return {
    fullName,
    profileImage,
    email,
    id,
    firstName,
    hasDefaultPaymentMethod,
    phoneNumber,
  };
};

export const getBlogData = blog => {
  // Get the base URL from the configuration.
  const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;

  // Get the blog images and generate image URLs using the base URL.
  const blogImages = blog?.attributes?.images?.data || [];
  const imagesLink = blogImages?.length && getImagesLink(blogImages, baseUrl);

  // Get the blog title and description.
  const title = blog?.attributes?.title || '';
  const description = blog?.attributes?.description || '';
  const tags = blog?.attributes?.tags || '';
  const createdAt = blog?.attributes?.createdAt || '';

  // Return an object containing the blog title, description, and the first image URL (if available).
  return { title, description, tags, createdAt, image: imagesLink && imagesLink[0] };
};

export const extractAndFormatBoundsFromString = queryString => {
  // Extract the query string part from the URL
  const queryParamsPart = queryString.split('?')[1];

  // Use URLSearchParams to parse the query parameters
  const params = new URLSearchParams(queryParamsPart);
  const bounds = params.get('bounds');

  // Check if bounds parameter exists
  if (!bounds) {
    console.log('Bounds parameter not found in the URL');
    return null;
  }

  // Split the bounds string into individual coordinates
  const coordinates = bounds.split(',');

  // Ensure we have exactly four coordinates for NE and SW bounds
  if (coordinates.length !== 4) {
    console.log('Invalid bounds format');
    return null;
  }

  // Format the coordinates: NE lat, NE lng, SW lat, SW lng
  const formattedBounds = coordinates.map(coord => coord.trim()).join(',');

  return formattedBounds;
};

export const getImagesLink = (images, url) => {
  // Generate an array of image URLs using the base URL and the image object's URL attribute.
  const imageUrls =
    isArrayLength(images) &&
    images.map(image => {
      const imageUrl = `${url}${image?.attributes?.url}`;
      return imageUrl;
    });

  // Return the array of image URLs.
  return imageUrls;
};
