export const MOORING_BUOY = 'mooringBuoy';
export const PUBLIC_MOORAGES = 'Public Moorages';

export const BOOKING_PROCESSES = {
  CHARITY_BOOKING_PROCESS_ALIAS: 'charity-booking/release-1',
};

//Note price is in cents
export const EXTENDED_PRICING_DATA = {
  FOR_LARGE_BUOY: 6000,
  FOR_LARGE_DOCK: 10000,
  FOR_BUOY: 3000,
  FOR_DOCK: 5000,
};
