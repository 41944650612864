import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-lopez-island',
    predictionPlace: {
      address: 'Lopez Island, Washington, United States',
      bounds: new LatLngBounds(
        new LatLng(48.581836, -122.7732818),
        new LatLng(48.3967984, -122.9675393)
      ),
    },
  },
  {
    id: 'default-marrowstone-washington',
    predictionPlace: {
      address: 'Marrowstone, Washington, United States',
      bounds: new LatLngBounds(
        new LatLng(48.103214, -122.669008),
        new LatLng(48.008584, -122.725923)
      ),
    },
  },
  {
    id: 'default-bainbridge-island',
    predictionPlace: {
      address: 'Bainbridge Island, Washington, United States',
      bounds: new LatLngBounds(
        new LatLng(47.7306907, -122.4524702),
        new LatLng(47.5575004, -122.603)
      ),
    },
  },
  {
    id: 'default-kakebay-washington',
    predictionPlace: {
      address: 'Lakebay, Washington, United States',
      bounds: new LatLngBounds(
        new LatLng(47.345531, -122.7086609),
        new LatLng(47.2439409, -122.8539912)
      ),
    },
  },
];
export default defaultLocations;
