import React from 'react';
import Popup from 'reactjs-popup';
import './InfoPopup.css';

function InfoPopup(props) {
  const { info = '' } = props;
  return (
    <Popup
      trigger={open => (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer' }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.53846C7.32682 3.53846 3.53846 7.32682 3.53846 12C3.53846 16.6732 7.32682 20.4615 12 20.4615C16.6732 20.4615 20.4615 16.6732 20.4615 12C20.4615 7.32682 16.6732 3.53846 12 3.53846ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
            fill="#030D45"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 8.15385C11.2919 8.15385 10.7179 8.72784 10.7179 9.4359C10.7179 9.86073 10.3736 10.2051 9.94872 10.2051C9.52388 10.2051 9.17949 9.86073 9.17949 9.4359C9.17949 7.87817 10.4423 6.61539 12 6.61539C13.5577 6.61539 14.8205 7.87817 14.8205 9.4359C14.8205 9.99624 14.6563 10.5206 14.3731 10.9606C14.1958 11.2363 13.9884 11.4994 13.7972 11.7371C13.7622 11.7805 13.7279 11.8231 13.6941 11.8649C13.5355 12.0612 13.3898 12.2415 13.2553 12.4264C12.9234 12.8826 12.7692 13.2253 12.7692 13.5385C12.7692 13.9633 12.4248 14.3077 12 14.3077C11.5752 14.3077 11.2308 13.9633 11.2308 13.5385C11.2308 12.7187 11.6332 12.0408 12.0112 11.5213C12.171 11.3018 12.346 11.0853 12.5051 10.8884C12.537 10.849 12.5683 10.8103 12.5986 10.7726C12.7885 10.5365 12.9502 10.3289 13.0794 10.1282C13.2076 9.92891 13.2821 9.69227 13.2821 9.4359C13.2821 8.72784 12.7081 8.15385 12 8.15385Z"
            fill="#030D45"
          />
          <path
            d="M13.0256 16.6154C13.0256 17.1818 12.5664 17.641 12 17.641C11.4336 17.641 10.9744 17.1818 10.9744 16.6154C10.9744 16.0489 11.4336 15.5897 12 15.5897C12.5664 15.5897 13.0256 16.0489 13.0256 16.6154Z"
            fill="#030D45"
          />
        </svg>
      )}
      position="bottom center"
      closeOnDocumentClick
    >
      {info}
    </Popup>
  );
}

export default InfoPopup;
