export const encryptString = (plainText, secretKey) => {
  let encryptedText = '';
  for (let i = 0; i < plainText.length; i++) {
    const plainCharCode = plainText.charCodeAt(i);
    const keyCharCode = secretKey.charCodeAt(i % secretKey.length);
    const encryptedCharCode = (plainCharCode + keyCharCode) % 256;
    encryptedText += String.fromCharCode(encryptedCharCode);
  }
  return encryptedText;
};

export const decryptString = (encryptedText, secretKey) => {
  let decryptedText = '';
  for (let i = 0; i < encryptedText.length; i++) {
    const encryptedCharCode = encryptedText.charCodeAt(i);
    const keyCharCode = secretKey.charCodeAt(i % secretKey.length);
    const decryptedCharCode = (encryptedCharCode - keyCharCode + 256) % 256;
    decryptedText += String.fromCharCode(decryptedCharCode);
  }
  return decryptedText;
};
