import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { getDefaultTimeZoneOnBrowser } from '../../../../util/dates';
import { propTypes } from '../../../../util/types';
import { Button, H3, IconSpinner, Icons } from '../../../../components';
import CustomRangeInput from '../CustomRangeInput/CustomRangeInput';

import css from './EditListingAvailabilityPanel.module.css';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {
  requestAddAvailabilityException,
  requestDeleteAvailabilityException,
} from '../../EditListingPage.duck';
import { isArrayLength } from '../../../../util/genericHelpers';

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onNextTab,
    onSubmit,
    allExceptions,
    addExceptionInProgress,
    isNewListingFlow,
    availabilityTab,
    viewport,
  } = props;

  const dispatch = useDispatch();

  const [showLoader, setLoader] = useState(false);
  const [cacheLoader, setCacheLoader] = useState(false);
  const [cacheLoaderReady, setCacheLoaderReady] = useState(false);
  const [deleteExceptionId, setDeleteExceptionId] = useState(null);
  const [clearDates, setClearDates] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [markUnavailable, onSetMarkUnavailable] = useState(false);

  const classes = classNames(
    rootClassName || css.root,
    className,
    availabilityTab ? css.availabilityTab : null
  );
  const listingAttributes = listing?.attributes;

  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
    ],
  };

  const availabilityPlan = defaultAvailabilityPlan;

  const handleSubmit = () => {
    setCacheLoader(true);
    return onSubmit({
      id: listing?.id,
      availabilityPlan,
    })
      .then(() => {
        setCacheLoader(false);
        setCacheLoaderReady(true);
        if (isNewListingFlow) {
          onNextTab();
        }
      })
      .catch(e => {
        setCacheLoader(false);
        setCacheLoaderReady(false);
        // Don't close modal if there was an error
      });
  };

  useEffect(() => {
    if (!focusedInput) {
      setFocusedInput('startDate');
    }
  }, [focusedInput]);

  const isDateBlocked = day => {
    // Block dates before today
    if (day.isBefore(moment().startOf('day'))) {
      return true;
    }

    if (isArrayLength(allExceptions)) {
      const dayMoment = moment(day);

      // Check if the day is within any of the exception date ranges
      const isBlockedByExceptions = allExceptions?.some(exception => {
        const exceptionStart = moment(exception?.attributes?.start);
        const exceptionEnd = moment(exception?.attributes?.end);
        return dayMoment.isBetween(exceptionStart, exceptionEnd, 'day', '[]');
      });

      return isBlockedByExceptions;
    }
  };

  const renderAvailabilityContent = (
    <>
      <div className={css.sidebarBlock}>
        <div className={css.availableDays}>
          <span
            className={css.markAvailability}
            onClick={() => onSetMarkUnavailable(!markUnavailable)}
          >
            Mark as unavailable
            {markUnavailable ? <Icons name="redCircle" /> : <Icons name="blankCircle" />}
          </span>
          {markUnavailable ? (
            <div className={css.availabilityExceptionButtons}>
              <Button
                type="button"
                disabled={!endDate}
                inProgress={addExceptionInProgress}
                onClick={() => {
                  const isSingleDay = moment(endDate).diff(moment(startDate), 'days') == 0;

                  // Assuming startDate and endDate are in the same timezone
                  const startOfDay = moment(startDate)
                    .tz(defaultTimeZone())
                    .startOf('day')
                    .toDate();

                  const endOfDay = moment(endDate)
                    .tz(defaultTimeZone())
                    .toDate();
                  if (isSingleDay) {
                    console.log(isSingleDay);
                    endOfDay.setHours(23, 55, 0, 0);
                  } else {
                    endOfDay.setHours(0, 0, 0, 0);
                  }
                  dispatch(
                    requestAddAvailabilityException({
                      listingId: listing?.id,
                      start: startOfDay,
                      end: endOfDay,
                      seats: 0,
                    })
                  )
                    .then(() => {})
                    .catch(() => {})
                    .finally(() => {
                      setStartDate(null);
                      setEndDate(null);
                    });
                }}
              >
                Block Dates
              </Button>
              <Button
                disabled={!startDate}
                type="button"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
              >
                Clear
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );

  return (
    <main className={classes}>
      <div
        className={css.pageHeading}
        style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
      >
        <H3 as="h2">Availability</H3>
        <p>
          Select any date ranges you wish to block below. To block a single day, please double-click
          on the desired date to confirm your selection.
        </p>
      </div>
      <div className={css.customCalendarSec}>
        <CustomRangeInput
          isAvailablityCalendar={true}
          onDatesChange={() => setClearDates(false)}
          numberOfMonths={2}
          clearDates={clearDates}
          allExceptions={allExceptions}
          startDate={startDate}
          endDate={endDate}
          isOutsideRange={isDateBlocked}
          setStartDate={d => {
            setStartDate(d);
            setEndDate(d);
          }}
          setEndDate={d => {
            onSetMarkUnavailable(true);
            setEndDate(d);
          }}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
          className={css.customCalendar}
          viewport={viewport}
        />
        <aside className={css.sideBar}>{renderAvailabilityContent}</aside>
      </div>
      <div className={css.allExceptions}>
        <h4>Blocked Dates</h4>
        {isArrayLength(allExceptions)
          ? allExceptions?.map((a, i) => (
              <div className={css.exceptionDates} key={i}>
                <span className={css.startDate}>
                  {moment(a?.attributes?.start).format('MM/DD/YYYY')}
                </span>
                <span className={css.endDate}>
                  {moment(a?.attributes?.end).format('MM/DD/YYYY')}
                </span>
                <span
                  className={css.remove}
                  onClick={() => {
                    setLoader(true);
                    setDeleteExceptionId(a?.id?.uuid);
                    dispatch(
                      requestDeleteAvailabilityException({
                        id: a?.id,
                      })
                    ).then(() => {
                      setLoader(false);
                    });
                  }}
                >
                  {showLoader && deleteExceptionId === a?.id?.uuid ? <IconSpinner /> : 'X'}
                </span>
              </div>
            ))
          : null}
      </div>
      <div className={css.actionBtnSec}>
        <Button
          className={css.submitButton}
          type="button"
          onClick={handleSubmit}
          inProgress={cacheLoader}
          ready={cacheLoaderReady}
        >
          {isNewListingFlow ? 'Next: Photos' : 'Save Availability'}
        </Button>
      </div>
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  monthlyExceptionQueries: null,
  weeklyExceptionQueries: null,
  allExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  monthlyExceptionQueries: object,
  weeklyExceptionQueries: object,
  allExceptions: arrayOf(propTypes.availabilityException),
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
