import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button, IconEmailAttention, IconReviewUser, Modal } from '../../../components';

import css from './NotificationModal.module.css';
import { useSelector } from 'react-redux';
import { currentUserHasListingsSelector } from '../../../ducks/user.duck';

const NotificationModal = props => {
  const {
    className,
    rootClassName,
    id = 'NotificationModal',
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'NotificationModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      hideCloseButton
      closeButtonMessage={closeButtonMessage}
    >
      <IconEmailAttention className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="NotificationModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="NotificationModal.description" />
      </p>
      <Button type="button" onClick={onCloseModal}>
        <FormattedMessage id="NotificationModal.goToMyInbox" />
      </Button>
    </Modal>
  );
};

export default injectIntl(NotificationModal);
