import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { required } from '../../../../util/validators';

// Import shared components
import {
  Form,
  Button,
  FieldTextInput,
  MapFormField,
  FieldDateInput,
  FieldSelect,
  FileUploadField,
  InfoPopup,
} from '../../../../components';

import css from './EditListingFeaturesForm.module.css';
import { anchorSystemCategories } from '../../../../config/customConfig';

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingFeaturesForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Form that asks title, description, transaction process and unit type for pricing
// In addition, it asks about custom fields according to marketplace-custom-config.js
const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
      } = formRenderProps;

      const hasInspectionReport = values?.inspectionReport;
      const savedInspectionReportName = hasInspectionReport
        ? hasInspectionReport?.split('/')?.pop()
        : null;
      const [objectURL, setObjectURL] = useState(hasInspectionReport || null);
      const [objectName, setObjectName] = useState(savedInspectionReportName);

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FieldTextInput
                id="maxBoatSize"
                name="maxBoatSize"
                className={css.textField}
                type="number"
                label={intl.formatMessage({ id: 'EditListingFeaturesForm.maxBoatSize' })}
                placeholder={intl.formatMessage({
                  id: 'EditListingFeaturesForm.maxBoatSizePlaceholder',
                })}
                validate={required(
                  intl.formatMessage({
                    id: 'EditListingFeaturesForm.maxBoatSizeRequired',
                  })
                )}
              />
            </div>
          </div>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FieldTextInput
                id="waterDepth"
                name="waterDepth"
                className={css.textField}
                type="number"
                label={
                  <>
                    {intl.formatMessage({ id: 'EditListingFeaturesForm.waterDepth' })}
                    {
                      <InfoPopup
                        info={intl.formatMessage({
                          id: 'EditListingFeaturesForm.waterDepthExtraInfo',
                        })}
                      />
                    }
                  </>
                }
                placeholder={intl.formatMessage({
                  id: 'EditListingFeaturesForm.waterDepthPlaceholder',
                })}
                validate={required(
                  intl.formatMessage({
                    id: 'EditListingFeaturesForm.waterDepthRequired',
                  })
                )}
              />
            </div>
          </div>

          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FieldTextInput
                id="registration"
                name="registration"
                className={css.textField}
                type="text"
                label={intl.formatMessage({ id: 'EditListingFeaturesForm.registration' })}
              />
            </div>
          </div>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FieldDateInput
                className={css.lastInspection}
                id={'lastInspection'}
                name={'lastInspection'}
                isOutsideRange={() => false}
                label={intl.formatMessage({ id: 'EditListingFeaturesForm.lastInspection' })}
                placeholder={intl.formatMessage({
                  id: 'EditListingFeaturesForm.lastInspectionPlaceholder',
                })}
              />
            </div>
          </div>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FieldSelect
                className={css.category}
                name="anchorSystemCategories"
                id="anchorSystemCategories"
                label={intl.formatMessage({ id: 'EditListingFeaturesForm.anchorSystemCategories' })}
                validate={required(
                  intl.formatMessage({
                    id: 'EditListingFeaturesForm.anchorSystemCategoriesRequired',
                  })
                )}
              >
                {anchorSystemCategories.map(c => (
                  <option key={c.key} value={c.key} disabled={c?.disabled}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
            </div>
          </div>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FileUploadField
                className={css.dynamicField}
                form={form}
                objectURL={objectURL}
                objectName={objectName}
                setObjectURL={url => {
                  setObjectURL(url);
                  form.change('inspectionReport', url);
                }}
                setObjectName={setObjectName}
                id="inspection"
                name="inspection"
                fieldLabel={intl.formatMessage({ id: 'EditListingFeaturesForm.inspectionReport' })}
              />
            </div>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingFeaturesForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingFeaturesFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFieldsConfig,
};

export default compose(injectIntl)(EditListingFeaturesFormComponent);
