import moment from 'moment';
import { useCallback, useRef, useState } from 'react';

export const charityOrganizations = [
  { 'friends-of-the-san-juans': 'Friends of the San Juans', code: 'CHARITY_ID_FSJ' },
  { 'northwest-straits-foundation': 'Northwest Straits Foundation', code: 'CHARITY_ID_NSF' },
  { 'puget-soundkeeper-alliance': 'Puget Soundkeeper Alliance', code: 'CHARITY_ID_PSA' },
];

/**
 * Returns a capitalized string.
 * @param {string} str - The string to capitalize.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 */
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return (str.charAt(0).toUpperCase() + str.slice(1)).trim();
};

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const getProductImages = images => {
  if (!Array.isArray(images)) {
    return [];
  }
  return images
    .map(
      item =>
        item?.attributes?.variants &&
        (item?.attributes?.variants['scaled-medium']?.url ||
          item?.attributes?.variants['listing-card-2x']?.url)
    )
    .filter(Boolean);
};
export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

const isImage = ['gif', 'jpg', 'jpeg', 'png', 'tiff', 'bmp', 'heic', 'svg', 'webp'];
const isVideo = ['mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'mp4', 'mov', 'wmv', 'flv', 'avi', 'hevc'];

export const getAttachmentType = att => {
  const attachment = att?.attributes?.content;
  const extension =
    attachment &&
    attachment
      .split(/[#?]/)[0]
      .split('.')
      .pop()
      .trim();

  const lowerCaseExtension = extension && typeof extension === 'string' && extension.toLowerCase();
  const hasImage = extension && isImage && isImage.includes(lowerCaseExtension);
  const hasVideo = extension && isVideo && isVideo.includes(lowerCaseExtension);
  return hasImage || hasVideo
    ? { type: hasImage ? 'image' : 'video', link: attachment }
    : attachment;
};

export const formatDateWithRelativity = createdAt => {
  // Use Moment.js to parse the createdAt date
  const date = moment(createdAt);

  // Define custom formatting rules
  const formatRules = {
    sameDay: '[Today], h:mm A', // Display "Today" with time for the current day
    nextDay: '[Tomorrow]', // This can remain as per your original structure or adjusted as needed
    nextWeek: 'dddd', // Day of the week for next week
    lastDay: '[Yesterday]', // Display "Yesterday" for the previous day
    lastWeek: '[Last] dddd', // Display "Last" with the day of the week for the previous week
    sameElse: 'DD/MM/YYYY', // Default date format for dates that don't match above rules
  };

  // Format the createdAt date using the defined rules
  return date.calendar(null, formatRules);
};

export const keepValidGermanChars = inputString => {
  // This regex matches all characters that aren't part of the German alphabet, numerics or spaces
  const regex = /[^a-zA-Z0-9äöüß ]/g;

  // Remove text enclosed within square brackets followed by a URL enclosed within parentheses
  const updatedString = inputString.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');

  // Replace all matches with an empty string
  return updatedString.replace(regex, ' ');
};
export const shuffleArray = arr => {
  const shuffledArray = [...arr];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const getBlogTags = blogs => {
  const tags = [];
  for (let i = 0; i < blogs?.length; i++) {
    const blog = blogs[i];
    tags.push(blog?.attributes?.tags);
  }
  const filteredTags = Array.from(new Set(tags.flat()));
  return tags ? filteredTags : [];
};

export const getDefaultLocale = () => {
  const defaultLocale =
    typeof window !== 'undefined' && window.localStorage.getItem('selectedLanguage');

  return defaultLocale || 'de';
};

export const sortArrayByLabel = arr => {
  const defaultLocale = getDefaultLocale();

  const collator = new Intl.Collator(defaultLocale, { sensitivity: 'base' });

  return (
    isArrayLength(arr) &&
    arr.sort((a, b) => {
      return collator.compare(a.label, b.label);
    })
  );
};

/**
 * Truncates a string to the specified length and adds an ellipsis if necessary.
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length of the text allowed before truncating.
 * @returns {string} The truncated text with an ellipsis if it was cut off.
 */
export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};

// Helper export  to check if exactly one of the values is truthy and value1 is a non-empty array
export function isOneTruthyAndValidArray(value1, value2) {
  // Check if value1 is a non-empty array and value2 is falsy
  const isValue1ValidArray = Array.isArray(value1) && value1.length > 0;
  // Check if exactly one of the values is truthy
  return (isValue1ValidArray && !value2) || (!isValue1ValidArray && value2);
}

export const extractSeoCategoryData = data => {
  const title = data?.attributes?.title;
  const description = data?.attributes?.description;
  const content = data?.attributes?.content;
  const heading = data?.attributes?.heading;
  const url = data?.attributes?.url;
  const headingImage = `${process.env.REACT_APP_STRAPI_BASE_URL}${data?.attributes?.headingImage?.data?.attributes?.url}`;

  return {
    title,
    description,
    content,
    heading,
    headingImage,
    url,
  };
};
