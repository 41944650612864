export const anchorSystemCategories = [
  {
    key: '',
    disabled: true,
    label: 'Please select an option',
  },
  {
    key: 'a1',
    label: 'Embedded anchor',
  },
  {
    key: 'a2',
    label: 'Dead weight anchor',
  },
];
