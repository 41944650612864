import React, { createElement, Fragment } from 'react';
import { default as unified } from 'unified';
import remarkParse from 'remark-parse';
import remark2rehype from 'remark-rehype';
import rehypeSanitize from 'rehype-sanitize';
import rehypeReact from 'rehype-react';
import { isArrayLength } from './genericHelpers';

export const convertMarkdownToReact = (markdownContent, shouldSplit = false) => {
  let content = markdownContent;

  if (isArrayLength(markdownContent)) {
    content = markdownContent[0];
  }

  if(!content){
    return ""
  }

  const splittedMarkdown = content
    .split(' ')
    .slice(0, 25)
    .join(' ');
  const truncatedContent = shouldSplit ? splittedMarkdown + '...' : content;
  const result = unified()
    .use(remarkParse)
    .use(remark2rehype)
    .use(rehypeSanitize)
    .use(rehypeReact, { createElement, Fragment })
    .processSync(truncatedContent);
  return result.result;
};
