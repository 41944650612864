import React from 'react';
import { useIntl } from '../../util/reactIntl';
import { ExternalLink } from '../../components';
import { Icons } from '../../components';
import { LinkedLogo } from '../../components';

import css from './FooterContainer.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
function FooterSection({ title, links, target = '_blank' }) {
  return (
    <div className={css.linkBlock}>
      <h2>{title}</h2>
      {links.map((link, index) => (
        <a key={index} className={css.navLink} target={link?.target || target} href={link.name}>
          {link.label}
        </a>
      ))}
    </div>
  );
}

function FooterContainer() {
  const intl = useIntl();
  const windowWidth = typeof window !== 'undefined' && window.innerWidth;
  const isMobileLayout = windowWidth < MAX_MOBILE_SCREEN_WIDTH;

  const companyLinks = [
    { name: `${ROOT_URL}/blog`, label: 'OnBuoy Blog', target: '_self' },
    { name: `${ROOT_URL}/locations`, label: 'Browse by region', target: '_self' },
    {
      name: `${ROOT_URL}/help/general-faqs/3/how-onbuoy-benefits-the-environment`,
      label: 'How OnBuoy helps the environment',
      target: '_blank',
    },
    { name: `${ROOT_URL}/p/become-a-host`, label: 'Why become a host' },
  ];

  const communityLinks = [
    { name: `${ROOT_URL}/help`, label: 'Frequently Asked Questions' },
    { name: `${ROOT_URL}/help/general-faqs/5/what-is-onbuoy`, label: 'What is OnBuoy?' },
    {
      name: `${ROOT_URL}/help/general-faqs/74/contact-us`,
      label: 'Contact Us',
    },
  ];

  const topLocationsLinks = [
    {
      name: `${ROOT_URL}/s?address=&bounds=48.88869801%2C-121.13315565%2C46.80367813%2C-124.09667354&mapSearch=true&search=`,
      label: 'Moorings in Puget Sound',
    },
    {
      name: `${ROOT_URL}/s?address=Seattle%2C%20Washington%2C%20United%20States&bounds=47.7379309%2C-122.215854%2C47.4772268%2C-122.4628463&search=Seattle%2C%20Washington%2C%20United%20States`,
      label: 'Moorings in Seattle',
    },
    {
      name: `${ROOT_URL}/s?address=Washington%20State%20Parks%2C%20Blake%20Island%2C%20Port%20Orchard%2C%20Washington%2098353%2C%20United%20States&bounds=47.61546456%2C-122.3722483%2C47.4594461%2C-122.59265269&mapSearch=true&search=Washington%20State%20Parks%2C%20Blake%20Island%2C%20Port%20Orchard%2C%20Washington%2098353%2C%20United%20States`,
      label: 'Moorings near Blake Island',
    },
  ];
  let deferredPrompt;
  if (typeof window !== 'undefined') {
    window.addEventListener('beforeinstallprompt', e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
    });
  }

  // Function to trigger the add to home screen prompt
  function addToHomeScreen() {
    if (deferredPrompt) {
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          // console.log('User accepted the A2HS prompt');
        } else {
          // console.log('User dismissed the A2HS prompt');
        }
        // Set deferredPrompt to null so that prompt can be shown again
        deferredPrompt = null;
      });
    }
  }

  return (
    <div className={css.footer}>
      <div className={css.contentWidth}>
        <div className={css.footerTop}>
          <FooterSection title="Explore" target="_self" links={companyLinks} />
          <FooterSection title="Help & Support" target="_self" links={communityLinks} />
          {isMobileLayout ? null : (
            <FooterSection title="Top Locations" links={topLocationsLinks} />
          )}
          <div className={css.linkBlock}>
            <h2>{intl.formatMessage({ id: 'FooterContainer.connectWithUs' })}</h2>
            <div className={css.socialLinks}>
              <ExternalLink href="https://www.facebook.com/OnBuoySPC" className={css.navLink}>
                <Icons name="facebook" />
              </ExternalLink>
              <ExternalLink className={css.navLink} href="https://www.instagram.com/onbuoyspc">
                <Icons name="instagram" />
              </ExternalLink>
              <ExternalLink className={css.navLink} href="https://www.linkedin.com/company/onbuoy/">
                <Icons name="linkedin" />
              </ExternalLink>
              <ExternalLink className={css.navLink} href="https://www.youtube.com/@OnBuoy">
                <Icons name="youtube" />
              </ExternalLink>
              <div onClick={addToHomeScreen} className={css.navLink}>
                <Icons name="toolsIcon" />
              </div>
            </div>
          </div>
        </div>
        <div className={css.footerBottom}>
          <LinkedLogo
            className={css.logoLink}
            layout="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
          <div className={css.rightLinks}>
            <p>{intl.formatMessage({ id: 'FooterContainer.ARR' })}</p>
            <a className={css.navLink} href="/privacy-policy">
              {intl.formatMessage({ id: 'FooterContainer.privacyPolicy' })}
            </a>
            <a className={css.navLink} href="/terms-of-service">
              {intl.formatMessage({ id: 'FooterContainer.termsOfUse' })}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterContainer;
